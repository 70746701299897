import { 
  createContext, 
  useCallback,
  useContext, 
  useEffect,
  useState 
} from 'react';

export const AuthContext = createContext();

function AuthProvider ({ 
  children, 
}) {

  const [isCheckingAuthStatus, setIsCheckingAuthStatus] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const checkAuthenticationStatus = useCallback(async () => {
    setIsCheckingAuthStatus(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/check`, {
        method: 'GET',
        credentials: 'include',
      });

      // data will be like: { isAuthenticated, userId }
      const data = await response.json();
      setIsAuthenticated(data.isAuthenticated);
      setUserId(data.userId);
      setEmailAddress(data.emailAddress);
      setIsAdmin(data.isAdmin)

    } catch (error) {
      console.error('Error checking authentication status:', error);
    } finally {
      setIsCheckingAuthStatus(false);
    }
  }, []);

  useEffect(() => {
    checkAuthenticationStatus();
  }, [checkAuthenticationStatus]);

  const logout = useCallback(async () => {
    setIsLoggingOut(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/signout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      const data = await response.json();

      if (data.status === 'failed' && data.message) {
        console.error(data.message);
        return;
      }

      setIsAuthenticated(false);
      setUserId(null);

    } catch (error) {

      console.error(error);

    } finally {

      setIsLoggingOut(false);

    }
  },[]);

  return (
    <AuthContext.Provider 
      value={{
        checkAuthenticationStatus,
        isAuthenticated,
        isAdmin,
        isCheckingAuthStatus,
        isLoggingOut,
        userId,
        emailAddress,
        logout,
      }}
    >
      { children }
    </AuthContext.Provider >
  );
}

export const withAuth = WrappedComponent => props => {
  const auth = useAuth();
  return <WrappedComponent {...props} {...auth} />;
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
