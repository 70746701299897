/** @jsxImportSource @emotion/react */
import { useState, useEffect, useRef } from 'react';

// if the message.date is less than 5 minutes old, return green
// if the message.date is less than 15 minutes old, return yellow
// otherwise return white
const getMessageContentColor = (message) => {
  const messageDate = new Date(message.date);
  const now = new Date();
  const diff = now - messageDate;
  const minutes = Math.floor(diff / 1000 / 60);
  if (minutes < 5) {
    return 'rgba(117, 237, 145, 1)';
  } else if (minutes < 15) {
    return 'rgba(174, 230, 187, 1)';
  } else {
    return 'white';
  }
};

const Messages = () => {
  
  // const { threads } = useLoaderData();
  const [messages, setMessages] = useState([]);
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);
  const conversationRef = useRef(null);
  const pollingInterval = 60000; // 60 seconds

  // Define the function to fetch messages
  const fetchMessages = async () => {
    try {
      setIsFetchingMessages(true);
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/user-messages`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },  
      });
      const data = await response.json();
      setMessages(data.messages);
    } catch (error) {
      console.error('Error fetching conversation history:', error);
    } finally {
      setIsFetchingMessages(false);
    }
  };

  // Initial fetch and setting up the polling
  useEffect(() => {
    fetchMessages();

    // Set up polling
    const intervalId = setInterval(fetchMessages, pollingInterval);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Scroll to the bottom of the conversation when messages change
  useEffect(() => {
    if (conversationRef.current) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div 
      css={{
        overflowY: 'hidden',
        position: 'relative',
      }}
    >
      <div
        css={{
          backgroundColor: '#333',
          padding: '6px 12px',
          color: 'white',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          fontSize: '12px',
          display: 'inline-block',
        }}
      >
        Latest Messages
      </div>
      { isFetchingMessages && (
        <div 
          css={{ 
            position: 'absolute',
            top: 5,
            color: '#fff',
            right: 28,
            fontSize: '12px'
          }}
        >
          Loading...
        </div> 
      )}
      <div
        css={{
          border: '1px solid #333',
          borderRadius: 5,
          wordWrap: 'break-word',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          padding: '20px',  
          color: 'white',    
          position: 'absolute',
          bottom: 20,
          top: 26,
          right: 20,
          left: 0,
        }} 
        ref={conversationRef}
      >
        {messages.map((message, index) => (
          <div 
            key={index} 
            css={{ 
              marginBottom: 20,
              '&:last-child': {
                marginBottom: 0,
              }
            }}
          >
            <div css={{ color: '#999', fontSize: '12px' }}>{new Date(message.date).toLocaleString()}</div>  
            <div css={{ color: '#999', fontSize: '12px' }}>{message.email_address}</div>
            <div css={{ color: getMessageContentColor(message)}}>{message.content}</div>
            <div css={{ color: '#999', fontSize: '12px' }}>{message.reply_content}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Messages;
