/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { Link, Outlet } from "react-router-dom";
import { useAuth } from '../providers/AuthProvider';
import Messages from '../shared/Messages';
import RegistrationForm from '../shared/RegistrationForm';
import AppHeader from '../shared/AppHeader';
import AppFooter from '../shared/AppFooter';
import { ReactComponent as Logo } from '../../images/logo.svg';

function App() {
  const { isCheckingAuthStatus, isAuthenticated, isAdmin } = useAuth();
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const updateHeight = () => {
    setViewportHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  if (isCheckingAuthStatus) return null;

  return (
    <div
      css={{
        position: 'relative',
        height: `${viewportHeight}px`,
        width: '100vw',
      }}
    >
      { isAuthenticated && isAdmin && (
        <div>
          <AppHeader />
          <div
            css={{
              position: 'absolute',
              top: 50,
              left: 0,
              right: 0,
              bottom: 24,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: 20,
              overflowY: 'hidden',
            }} 
          >
            <div/>
            <Messages />
          </div>
          <AppFooter />
        </div>
      )}
      { isAuthenticated && !isAdmin && (
        <div>You are NOT an admin!</div>
      )}
      { !isAuthenticated && (
        <div
          css={{
            backgroundImage: 'url(https://cdn.discordapp.com/attachments/1039227859845263420/1192219455032410233/thatjokerswild_a_hopeful_landscape_oil_painting_of_a_young_scie_ca77a729-5bda-43d0-a962-b61bf83edbd8.png?ex=65a847e4&is=6595d2e4&hm=bfa1df04362e6423393d682a550a3110768ac2ae910cb17ef05733763d6bf76b&)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            css={{
              width: '100%',
              height: '100%',
              background: 'linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.8), rgba(0,0,0,0.9))'
            }}
          >
            <div
              css={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                width: '100%',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                css={{
                  padding: '20px',
                  color: '#fff',
                  textAlign: 'center',
                  marginTop: 24,     
                }}
              >
                <Logo height="100" fill="black" />
              </div>   
              <RegistrationForm /> 
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                  textAlign: 'center',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  flex: 1,
                  paddingBottom: 20,
                  'a': {
                    fontSize: '11px',
                    color: '#999',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  },
                }}
              >
                <Link to='/terms'>Terms of Use</Link>
                <Link to='privacy'>Privacy Policy</Link>
              </div>  
            </div>
          </div>
        </div>
      )}
      <div><Outlet/></div>
    </div>
  );
}

export default App;
